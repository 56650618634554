<template>
  <div >
    <tableView
            :refresh="refresh8"
            :tableStatus='-1'
            :show-export="false"
    />
  </div>
</template>

<script>
import tableView from '@/components/finance/merchantcommission/table'
import checkPermission from '@/utils/permission'
export default {
  name:'commilist',
  components: { tableView},
  data(){
    return {
      refresh8:false
    }
  },
  methods:{
    checkPermission,
  }
}
</script>

<style scoped>

</style>
